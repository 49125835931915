import { getAxios, jsonAxios, postAxios, jsonAxiosDel } from '@/axios/index';

// 全员营销模块api
const promotionApiPath = {
  queryProduct: '/restManageToC/marketingProductController/queryProductByCache', // 产品推广列表接口
  listAddress: '/restManageToC/materialConroller/listAddressPage', // 活动推广列表接口
  listAddressMappingPosterUrls: '/restManageToC/materialConroller/listAddressMappingPosterUrls', // 查询地址关联海报 生成海报
  queryPromotionOrder: '/restManageToC/orderController/queryPromotionOrder', // C端查询推广订单列表
  queryPromotionOrderDetail: '/restManageToC/orderController/queryPromotionOrderDetail', // C端查询推广订单详情信息
  queryInvitationOrder: '/restManageToC/orderController/queryInvitationOrder', // C端查询推广C端查询邀请订单列表列表
  queryInvitationOrderDetail: '/restManageToC/orderController/queryInvitationOrderDetail', // C端查询推广订单详情C端查询邀请订单详情信息
  queryPromotionResultDetailPage: '/restManageToC/promotionResultDetailController/queryPromotionResultDetailPage', // C端获取待提现订单列表
  queryPromotionStatistics: '/restManageToC/promotionResultDetailController/queryPromotionStatistics', // C端获总订单数，总佣金
  saveSettlement: '/restManageToC/settlementController/saveSettlement', // C端提现
  queryCSettlementPage: '/restManageToC/settlementController/queryCSettlementPage', // 分页获取提现记录
  queryCSettlementDetailPage: '/restManageToC/settlementController/queryCSettlementDetailPage', // 分页获取提现明细
  queryWxAuthUrl: '/restManageToC/userWxParamController/queryWxAuthUrl', // 获取微信收款授权地址
  queryPersonalInfo: '/restManageToC/personalController/queryPersonalInfo', // 获取微信收款授权地址
  saveWxName: '/restManageToC/userWxParamController/saveWxName', // 保存微信收款真实姓名
  queryCWxParam: '/restManageToC/userWxParamController/queryCWxParam', // C端查询微信收款信息
  queryProductShare: '/restManageToC/marketingProductController/queryProductShare', // C端查询产品分享接口
  getPromoterByPromoteCode: '/restManageToC/personalController/getPromoterByPromoteCode', // 根据推广码获取推广员信息
  getInvitePoster: '/restManageToC/userInviteController/getInvitePoster', // 邀请海报链接
  pageInviteRecord: '/restManageToC/userInviteController/pageInviteRecord', // 我的邀请列表
  queryDistributionPromoterProductByCache: '/restManageToC/marketingProductController/queryDistributionPromoterProductByCache',
  queryUserInfo: '/restManageToC/personalController/queryUserInfo',
  // bindMobile: '/sendinfo-promotion/cPersonalService/bindPersonalMobile',
  pageLockFansRecord: '/restManageToC/lockFansController/pageLockFansRecord', // 我的邀请-锁粉记录
  queryUserGradeInfo: '/restManageToC/userGradeController/queryUserGradeInfo', // 获取推广员等级信息
  queryAddressQrCode: '/restManageToC/materialConroller/queryAddressQrCode', // 查询活动推广二维码或小程序码
  isIdCardDisplay: '/restManageToC/userWxParamController/isIdCardDisplay', // 结算账户授权后身份证显示
  queryRestrictPromoterInvitation: '/restManageToC/personalController/queryRestrictPromoterInvitation', // 查询推广员邀请禁用
  queryIsOpenServiceCharge: '/restManageToC/promotionResultDetailController/queryIsOpenServiceCharge', // 查询提现手续费开关
  queryProductByPromoteCode: '/restManageToC/marketingProductController/queryProductByPromoteCode',
  queryPersonalPolymerizeCode: '/restManageToC/personalController/queryPersonalPolymerizeCode',
  queryOldQyyxLoginInfo: '/restManageToC/personalController/queryOldQyyxLoginInfo', // 查询老全员迁移信息
  queryCFreshNoticeInfo: '/restManageToC/noticeInfoController/queryCFreshNoticeInfo', // 获取最新公告
  queryCNoticeInfoPage: '/restManageToC/noticeInfoController/queryCNoticeInfoPage', // 获取公告列表
  freshCNoticeInfo: '/restManageToC/noticeInfoController/freshCNoticeInfo', // 变更公告状态为已读
  queryCNoticeInfoDetail: '/restManageToC/noticeInfoController/queryCNoticeInfoDetail',
  isSettlement: '/restManageToC/settlementController/isSettlement',
};

const otherApiPath = {
  getCorpConfig: '/leaguer/api/promoter/getCorpConfig',
  checkUserPromoter: '/leaguer/api/promoter/checkUserPromoter', // 判断宋城用户否为新全员系统推广员
  checkBindingMobile: '/leaguer/api/userLeaguer/checkBindingMobile', // 判断宋城用户是否绑定手机号
  sendCheckCode: '/leaguer/api/userLeaguer/sendCheckCode', // 发送短信验证码
  bindingMobile: '/leaguer/api/userLeaguer/bindingMobile', // 用户绑定手机号
  checkCorpInviteUser: '/merchant/api/merchantWholeMarket/checkCorpInviteUser', // 判断是否是景区邀请注册白名单用户
  createUserAuth: '/leaguer/api/promoter/createUserAuth', // 注册成为全员推广员
  getMerchantPattern: '/merchant/api/merchantWholeMarket/getMerchantPattern', // 判断宋城店铺全员推广新老模式
  queryTotalSettlementFyc: '/leaguer/api/promoter/queryTotalSettlementFyc',
};

const baseUrl = '/qyyxApi';
// 独立部署根据用户id获取全员用户信息
const getQyyxToken = (params = {}) => {
  return jsonAxios('/web-platform/checkThirdInterfaceService/queryUserInfoByThirdParam?source=qyyx', params);
};

// 获取当前部署环境信息
const getDeployInfo = () => {
  return getAxios('/merchant/api/merchantWholeMarket/aloneDeploySwitch');
};

/**
   * 查询推广员产品推广列表
   * @param {*Number} promoterType 推广员类型：0-直客，1-分销商
   */
const queryProductByCache = (promoterType = 0, params = {}) => {
  const { queryDistributionPromoterProductByCache, queryProduct } = promotionApiPath;
  const url = promoterType
    ? queryDistributionPromoterProductByCache
    : queryProduct;
  return jsonAxiosDel(url, params, baseUrl);
};

const promotionApi = Object.keys(promotionApiPath).reduce((total, key) => {
  const path = promotionApiPath[key];
  total[key] = (params = {}) => {
    return jsonAxiosDel(path, params, baseUrl);
  };
  return total;
}, {});

const otherApi = Object.keys(otherApiPath).reduce((total, key) => {
  const path = otherApiPath[key];

  total[key] = (params = {}) => {
    return postAxios(path, params);
  };

  return total;
}, {});

export default {
  ...promotionApi,
  ...otherApi,
  getQyyxToken,
  getDeployInfo,
  queryProductByCache,
};
