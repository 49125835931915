import { postAxios } from '@/axios/index';

const state = {
  appId: '',
  appSecret: '',
  corpCode: '',
  transAuth: '',
};

const getters = {
  appId: state => state.appId,
  appSecret: state => state.appSecret,
};

const types = {
  SET_APP_ID: 'SET_APP_ID',
  SET_APP_SECRET: 'SET_APP_SECRET',
};

const mutations = {
  [types.SET_APP_ID](state, payload) {
    state.appId = payload;
  },
  [types.SET_APP_SECRET](state, payload) {
    state.appSecret = payload;
  },
};

const actions = {
  // 获取merchant店铺小程序配置信息
  async getMerchantWxConfig({ commit }) {
    const res = await postAxios('/merchant/api/merchantWholeMarket/getMerchantWxConfigByMerchantInfoId');
    if (res.status === 200) {
      const { appid, appsecret } = res.data;
      commit('SET_APP_ID', appid);
      commit('SET_APP_SECRET', appsecret);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
