export const payRouters = [{
  path: '/pay/main',
  name: 'payMain',
  component: resolve => require(['@/views/pay/payMain.vue'], resolve),
}, {
  path: '/pay/success',
  name: 'paySuccess',
  component: resolve => require(['@/views/pay/paySuccess.vue'], resolve),
}, {
  path: '/pay/fail',
  name: 'payFail',
  component: resolve => require(['@/views/pay/payFail.vue'], resolve),
}, {
  path: '/preOrderPay/result',
  name: 'payResult',
  component: resolve => require(['@/views/order/reservevation/payResult.vue'], resolve),
}];
