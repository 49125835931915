import Vue from 'vue';
import Router from 'vue-router';
import { homeRouters } from './home';
import { listRouters } from './list';
import { detailRouters } from './detail';
import { orderRouters } from './order';
import { loginRouters } from './login';
import { payRouters } from './pay';
import { memberRouters } from './member';
import cartRoute from './cartRoute';
import luckDrawRoute from './luckDrawRoute';
import promotionRoute from './promotion';
import couponRoute from './coupon';
import seatRouters from './seats';
import { isWxAli, compareDateTime, curentDateTime, add0, merchantInfoId } from '@/common/common';
import VueCookies from 'vue-cookies';
import miniProgram from '@/common/miniProgram';

Vue.use(Router);

const routes = [
  ...homeRouters,
  ...listRouters,
  ...detailRouters,
  ...orderRouters,
  ...loginRouters,
  ...payRouters,
  ...memberRouters,
  ...cartRoute,
  ...luckDrawRoute,
  ...promotionRoute,
  ...couponRoute,
  ...seatRouters,
];

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0,
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  // document.documentElement.scrollTop = 0;
  // next()
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.matched && to.matched.length) {
    if (to.fullPath === '/404') {
      next();
    } else {
      const spread_code = to.query.spread_code || to.query.promote_code || to.query.promoteCode;
      if (spread_code) {
        // 存在cookie中
        VueCookies.set(`spread_code_${merchantInfoId}`, spread_code, '1d', null, window.location.hostname);
        VueCookies.set('qyyxSpreadCode', spread_code, '1d', null, window.location.hostname);
      }

      const { spreadCode } = to.query;
      const { activeEndDate } = to.query;
      if (spreadCode) {
        // 存在cookie中
        VueCookies.set('spreadCode', spreadCode, '1d');
      }

      if (activeEndDate) {
        const time = new Date(Number(activeEndDate));
        const y = time.getFullYear();
        const m = time.getMonth() + 1;
        const d = time.getDate();
        const h = time.getHours();
        const mm = time.getMinutes();
        const s = time.getSeconds();
        const factEndDate = `${y}/${add0(m)}/${add0(d)} ${add0(h)}:${add0(mm)}:${add0(s)}`;

        const nowTime = curentDateTime();
        const ifExpired = compareDateTime(nowTime, factEndDate);

        if (ifExpired) {
          next('/expired');
        }
      }
      // 判断环境
      const env = isWxAli();
      // 微信或者阿里的时候
      if (env.isWx || env.isAliPay) {
        // 判断token是否存在
        if (to.query.token) {
          // 是否是数组
          const token = to.query.token instanceof Array
            ? to.query.token[to.query.token.length - 1]
            : to.query.token;
          // if (to.query.token instanceof Array) {
          //   localStorage.setItem('sc_token_20200216', to.query.token[to.query.token.length - 1]);
          // } else {
          //   localStorage.setItem('sc_token_20200216', to.query.token);
          // }
          localStorage.setItem('sc_token_20200216', token);
          if (miniProgram.isMP()) {
            VueCookies.set(`mini_token_${merchantInfoId}`, token, '8h', null, window.location.hostname);
            localStorage.setItem('token_type', 'mini');
          } else {
            VueCookies.set(`token_${merchantInfoId}`, token, '8h', null, window.location.hostname);
          }
        }
        if (to.query.leaguerInfoId) {
          // 是否是数组
          if (to.query.leaguerInfoId instanceof Array) {
            localStorage.setItem('sc_leaguerId_20200216', to.query.leaguerInfoId[to.query.leaguerInfoId.length - 1]);
          } else {
            localStorage.setItem('sc_leaguerId_20200216', to.query.leaguerInfoId);
          }
        }
      }

      next();
    }
  } else {
    next('/404');
  }
});
router.afterEach(() => {
  const bodySrcollTop = document.body.scrollTop;
  if (bodySrcollTop !== 0) {
    document.body.scrollTop = 0;
    return;
  }
  const docSrcollTop = document.documentElement.scrollTop;
  if (docSrcollTop !== 0) {
    document.documentElement.scrollTop = 0;
  }
});

export default router;
