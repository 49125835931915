<template>
  <div
    id="app"
    class="app-container"
  >
    <!-- <h2>{{isLoading}}</h2> -->
    <router-view></router-view>
    <!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive> -->
    <!-- </transition> -->
    <!-- <transition  mode="out-in"> -->
    <!-- <router-view v-if="!$route.meta.keepAlive"></router-view> -->
    <loading v-model="isLoading"></loading>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { Loading } from 'vux';

export default {
  name: 'App',
  components: {
    Loading,
  },
  computed: {
    ...mapState({
      isLoading: state => state.load.isLoading,
    }),
  },
};
</script>
<style lang="less">
@import "./assets/styles/reset.css";
@import './assets/styles/base.less';
@import "./style/common.less";
i,em{
  font-style: normal;
}
ul li {
  list-style: none;
}
#app {
  height: 100%;
}
html,
body {
  height: 100%;
}
body {
  background: #f1f1f1;
}
.mb10 {
  margin-bottom: 10px;
}
.m-b-10{
  margin-bottom: 10px;
}
.bg-w {
  background-color: #fff;
}
// input, textarea {
//   user-select:all !important;
//   -webkit-user-select: auto !important;
//   pointer-events: none;
// }
//轮播图
.videoClass {
  width: 100%;
  height: 221.5px;
}
.swiper-box {
  width: 100%;
  // height: 221.5px;
  .swiper-container {
    position: relative;
    height: 221.5px;
    .vux-swiper {
      height: 221.5px !important;
      .vux-indicator {
        bottom: 2px;
        a {
          margin-left: 5.5px;
          i {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.4);
            &.active {
              background-color: #fff !important;
            }
          }
        }
      }
      img {
        width: 100%;
        height: 221.5px;
      }
    }
  }
}
//推荐产品
.rmProduct_Box {
  width: 100%;
  overflow-x: auto;
}
.rmProduct_content {
  height: 160px;
}
.rmProduct_optBox {
  width: 150px;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  vertical-align: top;
}
.rmProduct_optBox_last {
  margin-right: 0;
}
.rmProduct_imgBox {
  width: 150px;
  height: 90px;
  background: #9c9c9c;
  border-radius: 5px;
  overflow: hidden;
}
.rmProduct_img {
  width: 150px;
  height: 90px;
}
.rmProduct_title {
  font-size: 14px;
  font-weight: 900;
  color: #413838;
  margin-top: 8px;
}
.rmProduct_subBox {
  width: 100%;
  font-size: 10px;
  color: #ef0017;
  margin-top: 10px;
}
.rmProduct_price {
  display: inline-block;
  font-size: 16px;
  color: #ef0017;
  font-weight: 900;
}
.rmProduct_saled {
  display: inline-block;
  float: right;
  margin-right: 10px;
  margin-top: 3px;
  color: #ababab;
}

//
.hotelCBox {
  height: 28px;
  display: inline-block;
  margin-top: 5px;
  border: 1px solid #ef0017;
  box-sizing: border-box;
  border-radius: 5px;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 10px;
  max-width: 100%;
}
.hotelCBox::-webkit-scrollbar {
  display: none;
}
.hotelContainer {
  height: 28px;
  white-space: nowrap;
}
.hotelC_block {
  width: 80px;
  line-height: 28px;
  text-align: center;
  font-size: 14px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.subHotele {
  box-shadow: 1px 0px 0px #d3d3d3;
}
.hotelTitle_choosed {
  background: #ef0017;
  color: white;
}
//预订须知弹框
.notice-modal {
  padding: 10px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
      text-align: left;
      width: 75%;
      font-size: 16px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* autoprefixer: ignore next */
      -webkit-box-orient: vertical;

      overflow: hidden;
    }
    .xx-icon {
      font-size: 20px;
      padding: 5px;
    }
  }
  .notice-book-content {
    width: 100%;
    margin-top: 10px;
    max-height: 250px;
    overflow-y: auto;
    text-align: left;
    word-break: break-all;
    padding: 0;
    img {
      width: 100%;
    }
  }
}

//优惠券弹框
.coupon-modal {
  // position: fixed;
  // top: 0;
  // right: 0;
  // width: 100%;
  // height: 100%;
  // background: #fff;
  z-index: 710 !important;
  // overflow-y: scroll;
  .modal-content {
    .couponBody {
      // position: absolute;
      // top: 69px;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #f7f7f7;
      .coupons_list {
        padding-top: 12px;
        .item-ul {
          padding: 0 8px;
          .item-li {
            margin-bottom: 20px;
            height: 144px;
            background: url("https://statics.lotsmall.cn/wx/img/coupons-bg.png")
              center center no-repeat;
            background-size: 100%;
            position: relative;
            .left {
              display: inline-block;
              width: 273px;
              .name {
                margin-top: 7px;
                color: #fff;
                font-size: 14px;
                font-family: PingFangSC-Regular, sans-serif;
                padding-left: 14px;
              }
              .price {
                padding-left: 40px;
                color: #fff;
                margin-top: 6px;
                margin-bottom: 9px;
                font-family: PingFangSC-Regular, sans-serif;
                .unit {
                  font-size: 18px;
                }
                .num {
                  font-size: 35px;
                }
              }
            }
            .date-use {
              position: absolute;
              left: 15px;
              bottom: 5px;
              font-size: 9px;
              color: #fff;
              font-family: PingFangSC-Semibold, sans-serif;
              .date {
                margin-bottom: 2.5px;
              }
              .use {
                margin-bottom: 5px;
              }
            }
            .right {
              height: 103px;
              display: inline-block;
              width: calc(100% - 273px);
              text-align: center;
              position: relative;
              .right-btn {
                border: none;
                width: 66px;
                height: 25px;
                line-height: 25px;
                border-radius: 2px;
                background: #fff;
                font-size: 14px;
                color: #f05b47;
                font-family: PingFangSC-Regular, sans-serif;
                margin-top: 44px;
                display: block;
              }
            }
          }
        }
        .btn {
          display: block;
          color: #413838;
          border-radius: 6px;
          width: 90%;
          height: 38px;
          line-height: 38px;
          margin-left: 5%;
          text-align: center;
          font-size: 15px;
          background-color: #fff;
          box-shadow: 0 0 10px rgba(245, 245, 245, 0.5);
          margin-bottom: 10px;
        }
      }
    }
  }
}
.fix_block_box {
  position: relative;
  width: 100%;
}
.fix_block_box_fixed {
  position: fixed;
  background-color: #fff;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
}
.fix_block-pad {
    background-color: #fff;
    padding: 0px 15px;
}
.fix_block-padTop {
    background-color: #fff;
    padding-top: 15px;
}
.fix_block-padBot {
    background-color: #fff;
    padding-bottom: 15px;
}


.agree-box {
    // display: flex;
    // align-items: center;
    label {
      // display: flex;
      // align-items: center;
      display: block;
      width: 100%;
    }
    input {
      display: none;
    }
    .agree-box-text{
      color: #FF8B59;
      font-size: 14px;
      line-height: 20px;
      margin: 10px;
      display: inline-block;
      vertical-align: middle;
      width: 290px;
    }
    .checkbox-moli {
      display: inline-block;
      vertical-align: middle;

      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #9a9a9a;
      background: #ffffff;
      position: relative;
    }
    .checkbox-moli::after {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 20px;
      height: 20px;
    }
    input:checked + .checkbox-moli {
      border-color: transparent;
    }
    input:checked + .checkbox-moli::after {
      background: url("./assets/images/checkbox.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      // transform: scale(1.3);
    }
  }
.notice_content{
  // height: 300px;
  max-height: 200px;
  overflow-y: scroll;
}
.jqDetail_contentBox_normal2{
  max-height: 90px;
  overflow: hidden;
}
.jqDetail_contentBox {
  position: relative;
}
.jqDetail_contentBox_normal {
  max-height: 160px;
  overflow: hidden;
}
.overflow {
  overflow: hidden;
  height: 100%;
}
.detail-more-btn {
  background-image: linear-gradient(-180deg,rgba(255,255,255,0) 0%,#fff 70%);
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  color: #333;
  bottom: -15px;
  left: 0;
  right: 0;
  padding-top: 40px;
  padding-bottom: 10px;
  position: absolute;
}

.btmRightSX,.btmRightSY {
    position: absolute;
    width: 90px;
    height: 90px;
    right: -17px;
    bottom: -17px;
  }
  .btmRightSX {
    background: url(~@/assets/images/yhq-ysx.png) no-repeat;
    background-size: 100% 100%;
  }
  .btmRightSY {
    background: url(~@/assets/images/yhq-ysy.png) no-repeat;
    background-size: 100% 100%;
  }
  .closeBtn {
    position: fixed;
    z-index: 100000;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, -130%);
    background: url(~@/assets/images/yhq-closeBtn.png) no-repeat;
    background-size: 100% 100%;
    border: 2px solid white;
    color: white;
    width: 24px;
    height: 24px;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    font-size: 17px;
  }
  .notice-dialog{
    .notice-content{
      padding: 20px;
    }
  }
  .privilege-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    .privilege-buy{
      background-color: #ef0017;
      color: #fff;
      height: 30px;
      line-height: 30px;
      margin-top: 5px;
      min-width: 110px;
      border-radius: 8px;
      text-align: center;
    }
  }
</style>
