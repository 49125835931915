const user = {
  namespaced: true, //不要修改
  state: {
    count: 0,
    info: {},
  },
  getters: {

  },
  mutations: {
    add(state, num) {
      state.count = state.count + num
    },
    setUser(state, value) {
      state.info = value;
    },
  },
  actions: {
    add: {
      root: true,
      handler({
        commit
      }, num) {
        commit('add', num)
      }
    }
  }
}

export default user