import md5 from 'js-md5';
import api from '@/api/promotion';
import { getUserInfo } from '@/ajax/member';

const {
  getQyyxToken,
  getDeployInfo,
  queryUserInfo,
  queryIsOpenServiceCharge,
  isIdCardDisplay,
  queryCWxParam,
  queryCFreshNoticeInfo,
  freshCNoticeInfo,
  queryCNoticeInfoPage,
  queryCNoticeInfoDetail,
  queryPersonalPolymerizeCode,
} = api;


const state = {
  qyyxToken: null, // 全员营销独立部署token
  isDeploySwitch: sessionStorage.getItem('deploy_switch') || null, // 当前环境是否为独立部署
  signKey: 'qyyx_sign_key', // 签名固定字符
  source: sessionStorage.getItem('source') || '', // 来源
  corpCode: sessionStorage.getItem('corp_code') || '', // 企业码
  thirdUserId: '',
  lotsToken: '',
  isThirdPart: false,
  userInfo: {
    mobile: '',
    accName: null, // 用户名
    accessToken: null, // token
    corpCode: '', // 全员企业码
    sysSource: '', // 系统来源
    userId: null, // 全员用户id
    promoterType: 0, // 推广员类型，0-直客推广员，1-分销商推广员
  },
  isShowIdcard: false,
  isBindMobile: '',
  isOpenServiceCharge: false,
  wxAccInfo: {
    accType: '2',
    wxAcc: '',
    wxName: '',
    wxIdcard: '',
    customerServiceUrl: '',
    isRecommend: '',
  },
  latestNotice: null,
  noticeList: [],
  noticeListTotal: 0,
  noticeDetails: {},
  polymerizeInfo: {
    wxQrCodeUrl: '',
    promoteCode: '',
    promoteUrl: '',
  },
};

const getters = {
  // qyyxToken: (state, getters) => {
  //   if (state.qyyxToken) return state.qyyxToken;
  //   if (state.isThirdPart) {
  //     return sessionStorage.getItem(`qyyx_${md5(getters.thirdUserId)}`) || '';
  //   } else {
  //     return sessionStorage.getItem(`qyyx_${md5(getToken())}`) || '';
  //   }
  // },
  // lotsToken: (state) => {
  //   if (!state.isThirdPart) return getToken();
  //   return state.lotsToken;
  // },
  isThirdPart: state => state.isThirdPart,
  isDeployAlone: (state) => state.isDeploySwitch,
  thirdUserId: (state) => state.thirdUserId,
  mobile: state => state.userInfo.mobile,
  promoterType: state => state.userInfo.promoterType,
  isBindMobile: state => state.isBindMobile,
  isShowIdcard: state => state.isShowIdcard,
  isOpenServiceCharge: state => state.isOpenServiceCharge,
  wxIdcard: state => state.wxAccInfo.wxIdcard,
  polymerizeInfo: state => state.polymerizeInfo,
  source: state => state.userInfo.sysSource || state.source,
  latestNotice: state => state.latestNotice,
  noticeList: state => state.noticeList,
  noticeListTotal: state => state.noticeListTotal,
  noticeDetails: state => state.noticeDetails,
};

const mutations = {
  SET_USERINFO(state, payload = {}) {
    state.userInfo = Object.assign(state.userInfo, payload);
  },
  SET_QYYXTOKEN(state, payload) {
    state.qyyxToken = payload;
    if (!state.isThirdPart) {
      // 通过lots token缓存
      const getKey = () => (state.lotsToken ? md5(state.lotsToken) : null);
      if (payload && getKey()) {
        Object.keys(sessionStorage).forEach(item => {
          if (item.includes('qyyx_')) {
            sessionStorage.removeItem(item);
          }
        });
        sessionStorage.setItem(`qyyx_${getKey()}`, payload);
      }
    } else {
      // 通过第三方id缓存
      const thirdUserId = state.thirdUserId || sessionStorage.getItem('third_user_id');
      const getKey = () => (thirdUserId ? md5(thirdUserId) : null);
      if (payload && getKey()) {
        Object.keys(sessionStorage).forEach(item => {
          if (item.includes('qyyx_')) {
            sessionStorage.removeItem(item);
          }
        });
        sessionStorage.setItem(`qyyx_${getKey()}`, payload);
      }
    }
  },
  SET_DEPLOYINFO(state, payload) {
    const { isDeploySwitch, source, corpCode } = payload;
    const deploy = isDeploySwitch === 'true' ? '1' : '2';
    sessionStorage.setItem('deploy_switch', deploy);
    state.isDeploySwitch = deploy;
    sessionStorage.setItem('source', source);
    state.source = source;
    sessionStorage.setItem('corp_code', corpCode);
    state.corpCode = corpCode;
  },
  SET_USERID(state, payload) {
    if (payload) {
      sessionStorage.setItem('third_user_id', payload);
      state.thirdUserId = payload;
    }
  },
  SET_THIRD_PART(state, payload) {
    state.isThirdPart = !!payload;
  },
  SET_IDCARDSWITCH(state, payload) {
    state.isShowIdcard = !!payload;
  },
  SET_IS_BIND_MOBILE(state, payload) {
    state.isBindMobile = payload;
  },
  SET_SERVICE_CHARGE(state, payload) {
    state.isOpenServiceCharge = !!payload;
  },
  SET_WXACCINFO(state, payload = {}) {
    state.wxAccInfo = Object.assign(state.wxAccInfo, payload);
  },
  SET_LATEST_NOTICE(state, payload) {
    if (payload) {
      state.latestNotice = Object.assign({}, payload);
    } else {
      state.latestNotice = null;
    }
  },
  SET_NOTICE_LIST(state, payload = []) {
    state.noticeList = [].concat(payload);
  },
  SET_NOTICE_LIST_TOTAL(state, payload) {
    state.noticeListTotal = payload;
  },
  SET_NOTICE_DETAILS(state, payload = {}) {
    state.noticeDetails = Object.assign({}, payload);
  },
  SET_POLYMERIZE_INFO(state, payload = {}) {
    state.polymerizeInfo = Object.assign(state.polymerizeInfo, payload);
  },
};

const actions = {
  // lots登录获取全员信息
  async FETCH_QYYXTOKEN({ commit, state, getters, dispatch }) {
    // lots登录userId
    if (!getters.thirdUserId) {
      await dispatch('FETCH_USERINFO');
    }
    const paramString = { thirdUserId: getters.thirdUserId, corpCode: state.corpCode };
    // 签名规则：md5(入参 json字符串 + qyyx_sign_key)
    const sign = md5(`${JSON.stringify(paramString)}${state.signKey}`);
    // const requestParam = JSON.stringify({ paramString: JSON.stringify(paramString), sign });
    const res = await getQyyxToken({ paramString: JSON.stringify(paramString), sign });
    if (res.code === 200) {
      const data = JSON.parse(res.data);
      commit('SET_USERINFO', data);
      commit('SET_QYYXTOKEN', data.accessToken);
    }
  },
  // 获取独立部署信息
  async FETCH_DEPLOYINFO({ commit }) {
    const res = await getDeployInfo();
    if (res.status === 200) {
      commit('SET_DEPLOYINFO', res.data);
    }
  },
  // 获宋城用户登录信息
  async FETCH_USERINFO({ commit }) {
    const res = await getUserInfo();
    if (res.status === 200) {
      const userId = res.data.id;
      userId && commit('SET_USERID', userId);
      const { mobile } = res.data;
      mobile && commit('SET_USERINFO', { mobile });
    }
  },
  // 获取推广员身份证校验开关
  async FETCH_IDCARDSWITCH({ commit }) {
    const res = await isIdCardDisplay();
    commit('SET_IDCARDSWITCH', res.data);
  },
  // 获取推广员信息
  async FETCH_PROMOTER_USERINFO({ commit }) {
    const res = await queryUserInfo();
    const { isBindMobile, promoterType } = res.data;
    commit('SET_IS_BIND_MOBILE', isBindMobile === 'T');
    commit('SET_USERINFO', { promoterType });
  },
  // 获取推广员提现手续费开关
  async FETCH_SERVICE_CHARGE({ commit }) {
    const res = await queryIsOpenServiceCharge();
    commit('SET_SERVICE_CHARGE', res.data);
  },
  // 获取微信结算账户信息
  async FETCH_WXACCINFO({ commit }) {
    const res = await queryCWxParam();
    commit('SET_WXACCINFO', res.data || {});
  },
  async FETCH_LATEST_NOTICE({ commit }) {
    const res = await queryCFreshNoticeInfo();
    if (res.code === 200) {
      commit('SET_LATEST_NOTICE', res.data || '');
    }
  },
  async READ_LATEST_NOTICE({ commit }, params) {
    const res = await freshCNoticeInfo(params);
    if (res.code === 200 && res.success) {
      commit('SET_LATEST_NOTICE', '');
    }
  },
  async FETCH_NOTICE_LIST({ commit }, params) {
    const res = await queryCNoticeInfoPage(params);
    if (res.code === 200) {
      commit('SET_NOTICE_LIST', res.data);
      commit('SET_NOTICE_LIST_TOTAL', res.total);
    }
  },
  async FETCH_NOTICE_DETAILS({ commit }, params) {
    const res = await queryCNoticeInfoDetail(params);
    if (res.code === 200) {
      commit('SET_NOTICE_DETAILS', res.data);
    }
  },
  // 获取聚合码信息
  async FETCH_POLYMERIZE_CODE({ commit }, params) {
    const res = await queryPersonalPolymerizeCode(params);
    if (res.code === 200) {
      const { wxQrCodeUrl, promoteCode, promoteUrl } = res.data;
      commit('SET_POLYMERIZE_INFO', { wxQrCodeUrl, promoteCode, promoteUrl });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
