export const memberRouters = [{
  path: '/list/order',
  name: 'memberOrderList',
  component: resolve => require(['@/views/member/order/index.vue'], resolve),
}, {
  path: '/member/order/detail',
  name: 'memberOrderDetail',
  component: resolve => require(['@/views/member/order/detail/index.vue'], resolve),
}, {
  path: '/member',
  name: 'member',
  component: resolve => require(['@/views/member/index.vue'], resolve),
}, {
  path: '/member/comment',
  name: 'comment',
  component: resolve => require(['@/views/member/comment/index.vue'], resolve),
}, {
  path: '/member/author',
  name: 'author',
  component: resolve => require(['@/views/member/author/index.vue'], resolve),
}, {
  path: '/member/invoice',
  name: 'invoice',
  component: resolve => require(['@/views/member/invoice/index.vue'], resolve),
}, {
  path: '/member/invoice/invoiceDetail',
  name: 'invoiceDetail',
  component: resolve => require(['@/views/member/invoice/invoiceDetail/index.vue'], resolve),
}, {
  path: '/member/invoice/invoicePay',
  name: 'invoicePay',
  component: resolve => require(['@/views/member/invoice/invoicePay/index.vue'], resolve),
}, {
  path: '/member/edit/editName',
  name: 'editName',
  component: resolve => require(['@/views/member/edit/editName/index.vue'], resolve),
}, {
  path: '/member/edit',
  name: 'edit',
  component: resolve => require(['@/views/member/edit/index.vue'], resolve),
}, {
  path: '/member/group/detail',
  name: 'groupDetail',
  component: resolve => require(['@/views/member/group/detail/index.vue'], resolve),
}, {
  path: '/member/group',
  name: 'group',
  component: resolve => require(['@/views/member/group/index.vue'], resolve),
}, {
  path: '/member/group/refund',
  name: 'groupRefund',
  component: resolve => require(['@/views/member/group/refund/index.vue'], resolve),
}, {
  path: '/member/detail',
  name: 'memberDetail',
  component: resolve => require(['@/views/member/detail/index.vue'], resolve),
}, {
  path: '/member/message',
  name: 'message',
  component: resolve => require(['@/views/member/message/index.vue'], resolve),
}, {
  path: '/member/coupon',
  name: 'coupon',
  component: resolve => require(['@/views/member/coupon/index.vue'], resolve),
}, {
  path: '/member/coupon/mycoupon',
  name: 'mycoupon',
  component: resolve => require(['@/views/member/coupon/mycoupon/index.vue'], resolve),
}, {
  path: '/member/showList',
  name: 'showList',
  component: resolve => require(['@/views/member/show/list/index.vue'], resolve),
}, {
  path: '/member/show',
  name: 'show',
  component: resolve => require(['@/views/member/show/newIndex.vue'], resolve),
  // component: resolve => require(['@/views/member/show/index.vue'], resolve)
}, {
  path: '/member/order/detail',
  name: 'orderDetail',
  component: resolve => require(['@/views/member/order/detail/index.vue'], resolve),
}, {
  path: '/member/order/evaluate',
  name: 'orderEvaluate',
  component: resolve => require(['@/views/member/order/evaluate/index.vue'], resolve),
}, {
  path: '/member/order/goodsSend',
  name: 'orderGoodsSend',
  component: resolve => require(['@/views/member/order/goodsSend/index.vue'], resolve),
}, {
  path: '/member/order',
  name: 'order',
  component: resolve => require(['@/views/member/order/index.vue'], resolve),
}, {
  path: '/member/order/tuikuan',
  name: 'tuikuan',
  component: resolve => require(['@/views/member/order/tuikuan/index.vue'], resolve),
}, {
  path: '/member/order/tuikuanDetail',
  name: 'tuikuanDetail',
  component: resolve => require(['@/views/member/order/tuikuanDetail/index.vue'], resolve),
}, {
  path: '/member/receiveCoupon',
  name: 'receiveCoupon',
  component: resolve => require(['@/views/member/receiveCoupon/index.vue'], resolve),
}, {
  path: '/member/topContacts',
  name: 'topContacts',
  component: resolve => require(['@/views/member/topContacts/index.vue'], resolve),
}, {
  path: '/member/topContacts/newContacts',
  name: 'newContacts',
  component: resolve => require(['@/views/member/topContacts/newContacts/index.vue'], resolve),
}, {
  path: '/member/seats/list',
  name: 'seatsList',
  component: resolve => require(['@/views/member/seats/list/index.vue'], resolve),
}, {
  path: '/member/seats/detail',
  name: 'seatsDetail',
  component: resolve => require(['@/views/member/seats/detail/index.vue'], resolve),
}, {
  path: '/member/integralDetail',
  name: 'integralDetail',
  component: resolve => require(['@/views/member/integralDetail/index.vue'], resolve),
}, {
  path: '/member/integralStore/list',
  name: 'integralStore',
  component: resolve => require(['@/views/member/integralStore/list'], resolve),
}, {
  path: '/member/memberCenter',
  name: 'MemberCenter',
  component: resolve => require(['@/views/member/memberCenter'], resolve),
}, {
  path: '/member/privilege',
  name: 'privilege',
  component: resolve => require(['@/views/member/privilege'], resolve),
}, {
  path: '/member/privilege/record',
  name: 'memberRecord',
  component: resolve => require(['@/views/member/privilege/record'], resolve),
}, {
  path: '/member/payResult',
  name: 'payResult',
  component: resolve => require(['@/views/member/payResult'], resolve),
}, {
  path: '/member/agreement',
  name: 'agreement',
  component: resolve => require(['@/views/member/agreement'], resolve),
}, {
  path: '/bonus/detail',
  name: 'bonusDetail',
  component: resolve => require(['@/views/member/bonus'], resolve),
}, {
  path: '/integralStore/list',
  name: 'integralStoreList',
  meta: {
    keepAlive: true,
    pageType: 'list',
    productType: '',
  },
  component: resolve => require(['@/views/member/integralStore/list'], resolve),
}, {
  path: '/integralStore/detail',
  name: 'integralStoreDetail',
  component: resolve => require(['@/views/member/integralStore/detail'], resolve),
}, {
  path: '/reservation/entrance',
  name: 'reservationIndex',
  component: resolve => require(['@/views/member/reservation/index'], resolve),
}];
export default memberRouters;
