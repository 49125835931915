export const homeRouters = [{
  path: '/',
  name: 'Home',
  component: r => require.ensure([], () => r(require('@/views/home/home')), 'Home'),
}, {
  path: '/customPage',
  name: 'customPage',
  component: resolve => require(['@/views/customPage/index.vue'], resolve),
}, {
  path: '/expired',
  name: 'expired',
  component: resolve => require(['@/views/expired/index.vue'], resolve),
}];
