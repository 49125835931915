const couponRoute = [
  {
    path: '/coupon/detail',
    name: 'couponsDetail',
    component: () => import(/* webpackChunkName: "route-coupons" */ '@/views/coupon/detail'),
    meta: {
      title: '优惠券领取详情',
    },
  },

  {
    path: '/coupon/success',
    name: 'couponsSuccess',
    component: () => import(/* webpackChunkName: "route-coupons" */ '@/views/coupon/success'),
    meta: {
      title: '优惠券领取成功',
    },
  },
];

export default couponRoute;
