import api from '@/api/api';
import { resizeImg } from '@/utils';
import { host_pub, pubUrl } from '@/common/common';
import { merchantInfoId } from '../common/common';

export default {
  data() {
    return {
      service: '',
      router: '/',
      // imgSrc: '/',
      api,
      corpCode: '',
      buttonClicked: false,
      navBarHeight: null,
      navContentHeight: 49,
      tabContentHeight: 40,
      iconData: {
        home: 'icon-nav-home',
        order: 'icon-nav-orders',
        personal: 'icon-member',
        shopcart: 'icon-shopping-car',
        custompage: 'icon-pintu',
        ticket: 'icon-nav-ticket',
        hotel: 'icon-nav-hotel',
        shop: 'icon-nav-goods',
        strategy: 'icon-nav-raiders',
        route: 'icon-nav-tours',
        repast: 'icon-nav-foods',
        ai_p: 'icon-AIpaiyipai',
      },
      linkUrlObj: {
        ticket: '/ticket/list',
        hotel: '/hotel/list',
        shop: '/goods/list',
        repast: '/repast/list',
        route: '/route/list',
        tickets: '/tickets/list',
        home: '/',
        strategy: '/strategy/list',
        shopcart: '/shoppingCart',
        personal: '/member',
        order: '/member/order',
        coupon: '/member/coupon',
        receivecoupons: '/member/receiveCoupon',
        accountcenter: '/member/edit',
        groupbooking: '/groupbooking/list',
        publicservice: '/tourGuide/list',
        tourGuide: '/tourGuide/list',
        parkcard: '/parkcard/list',
        // ai_p: "/ai_p",
        custompage: '/customPage',
        show: '/show/list',
        program: '/member/showList',
        seats: '/member/seats/list',
      },
      linkDetailObj: {
        ticket: '/detail/ticket',
        family: '/detail/tickets',
        hotel: '/detail/hotel',
        room: '/detail/hotel',
        show: '/detail/show',
        mdse: '/detail/shop',
        repast: '/detail/repast',
        route: '/detail/route',
        strategy: '/detail/strategy',
        ai_p: '/detail/ai_p',
        groupbooking: '/detail/groupbooking',
        publicservice: '/detail/tourGuide',
        tourGuide: '/detail/tourGuide',
        parkcard: '/detail/parkcard',
        // integralStore: '/integralStore/detail', // 积分商城详情页面
      },
    };
  },
  methods: {
    resizeImg,
    clickDebounce(fn, interval) {
      let timer;
      const gapTime = interval || 100;
      const _this = this;
      return function () {
        clearTimeout(timer);
        const context = _this;
        const args = arguments;// 保存此处的arguments，因为setTimeout是全局的，arguments不是防抖函数需要的。
        timer = setTimeout(() => {
          fn.call(context, args);
        }, gapTime);
      };
    },
    debounce(fn, interval) {
      let timer;
      const gapTime = interval || 100;
      const _this = this;
      return function () {
        clearTimeout(timer);
        const context = _this;
        const args = arguments;// 保存此处的arguments，因为setTimeout是全局的，arguments不是防抖函数需要的。
        timer = setTimeout(() => {
          fn.call(context, args);
        }, gapTime);
      };
    },
    goMemberPay() {
      this.$router.push('/member?from=detail');
    },
    checkToken(nextPath) {
      // 用于验证当前token过期或者不存在的问题
      if (nextPath) {
        // 用于window.locatin.href 跳转
        localStorage.setItem('sc_nextpath_20200216', nextPath);
      }
      return this.getAxios('/leaguer/api/leaguerContacts/getContactsList');
    },
    iosBlur() {
      const ua = window.navigator.userAgent;
      // $alert('浏览器版本: ' + app + '\n' + '用户代理: ' + ua);
      if (/\(i[^;]+;( U;)? CPU.+Mac OS X/.test(ua)) {
        // ios系统
        let currentPosition;
        let timer = null;
        const speed = 1;
        timer = setInterval(() => {
          currentPosition =
            document.documentElement.scrollTop || document.body.scrollTop;
          currentPosition -= speed;
          window.scrollTo(0, currentPosition); // 页面向上滚动
          currentPosition += speed;
          window.scrollTo(0, currentPosition); // 页面向下滚动
          clearInterval(timer);
        }, 100);
      }
    },
    tabNavurl(item, params) {
      const { linkUrl } = item;
      const id = item.customPageId;
      const { customTel } = item;

      if (linkUrl === 'customlink') {
        // 自定义链接
        window.location.href = item.customLinkurl;
        return;
      }
      let url = this.linkUrlObj[linkUrl];
      if (linkUrl === 'custompage') {
        // 自定义页面
        if (url === this.$route.path) {
          url = `${url }?id=${id}`;
          this.$router.push(url);
          return this.$router.go(0);
        }
        url = `${url }?id=${ id}`;
      } else if (linkUrl === 'product') {
        // 产品
        const {
          businessType,
        } = item.proInfo || {};
        if (businessType) {
          url = this.getDetailUrl({
            item,
            params,
          });
          window.location.href = url;
          return;
        }
      } else if (linkUrl === 'ai_p') {
        this.$vux.alert.show({
          title: '提示',
          content: '未购买服务或服务过期',
        });
        return;
      } else if (linkUrl === 'guide') {
        this.$vux.alert.show({
          title: '',
          content: '暂未开放',
        });
        return;
      } else if (linkUrl === 'groupbooking') {
        this.$vux.alert.show({
          title: '',
          content: '暂未开放',
        });
        return;
      } else if (linkUrl === 'parkcard') {
        this.$vux.alert.show({
          title: '',
          content: '暂未开放',
        });
        return;
      } else if (linkUrl === 'publicservice' || linkUrl === 'tourGuide') {
        const _this = this;
        this.getParkCorpCode(() => {
          // if (guideData.length > 1) {
          //   _this.$router.push(`${_this.linkUrlObj.tourGuide}?corpCode=${_this.corpCode}`);
          // } else {
          //   _this.$router.push(`${_this.linkDetailObj.tourGuide }?id=${ guideData[0].id }&parkId=${params.parkId}`);
          // }
          const url = `${pubUrl }/wap/#/tourGuide/list?m_id=${merchantInfoId}&corpCode=${_this.corpCode}`;
          window.location.href = url;
        }, params);
        return;
      } else if (linkUrl === 'theater') {
        this.$vux.alert.show({
          title: '',
          content: '暂未开放',
        });
        return;
      } else if (linkUrl === 'customtel') {
        window.location.href = `tel:${ customTel}`;
      } else if (linkUrl === 'program') {
        if (params) {
          url = `/member/show?id=${params}`;
        }
      } else if (linkUrl === 'promissoryNote') {
        if (item.modelCode) {
          url = `/order/${item.proType}?modelCode=${item.modelCode}`;
        }
      }
      this.$router.push(url);
    },
    searchCoupon(params, cb) {
      // params:{"totalPrice":10,"deductions":[{"productCode":"商品编码","num":"数量","price":"商品单价"}]}
      this.getAxios(this.api.main.marketing.searchCoupon, params).then(res => {
        cb && cb(res);
      });
    },
    // 查抵扣信息接口
    bonusDeductionUsingGET(params, cb) {
      this.getAxios('/leaguer/api/leaguer/pubs/bonus/deduction', params).then(res => {
        cb && cb(res);
      });
    },
    async getLeaguerInfo(cb) {
      this.getAxios('/leaguer/api/userLeaguer/manage/leaguerInfo', {
        leaguerId: localStorage.getItem('sc_leaguerId_20200216'),
      }).then(res => {
        if (Number(res.status) === 200) {
          cb && cb(res);
        }
      });
    },
    goUrl(type, id, code, pxType) {
      if (type !== '') {
        const url = this.linkDetailObj[type];
        if (type === 'shop' || type === 'mdse') {
          this.$router.push(`${url}?id=${id}&code=${code}`);
        } else if (type === 'strategy') {
          this.$router.push(`${url}?id=${id}`);
        } else if (type === 'groupbooking') {
          this.$vux.toast.text('我的拼团暂未开发!', 'middle');
        } else if (type === 'parkcard') {
          this.$vux.toast.text('公园年卡暂未开发!', 'middle');
        } else if (type === 'publicservice' || type === 'guide') {
          // this.$vux.toast.text('公共服务暂未开发!', "middle");
          // return
          // const _this = this;
          this.getParkCorpCode(() => {
            // if (guideData.length) {
            //   _this.$router.push(`${_this.linkDetailObj.tourGuide }?id=${ guideData[0].id}`);
            // } else {
            //   _this.$router.push(`${_this.linkUrlObj.tourGuide }?corpCode=${_this.corpCode}`);
            // }
            const url = `${pubUrl }/wap/#/tourGuide/list?m_id=${merchantInfoId}&corpCode=${this.corpCode}`;
            window.location.href = url;
          });
        } else if (pxType && pxType === 'single') {
          this.$router.push(`${url}?id=${id}&productCode=${code}&pProCode=${code}`);
        } else {
          // this.$router.push(`${url}?id=${id}&productCode=${code}`);
          // puhs到相同路由不会刷新
          window.location.href = `${url}?id=${id}&productCode=${code}`;
        }
      }
    },
    getDetailUrl({
      item,
      params,
    } = {}) {
      const {
        businessType,
        merchantProdcutId,
        productCode,
        pointId,
        familyId,
      } = item.proInfo;


      let url = this.linkDetailObj[businessType];
      if (pointId) {
        // 积分商城
        url = `/integralStore/detail?id=${pointId}`;
        return url;
      }
      if (businessType === 'family') {
        // 套票
        url += `?merchantFamilyInfoId=${familyId}`;
        return url;
      }

      if (params === 'single') {
        url += `?id=${merchantProdcutId }&productCode=${ productCode }&pProCode=${productCode}`;
      } else {
        url += `?id=${merchantProdcutId }&productCode=${ productCode}`;
      }
      return url;
    },
    // 导游导览 跳转
    getParkCorpCode(cb, parkInfo) {
      this.getAxios('/merchant/api/merchantInfo/getMerchantInfoById')
        .then(res => {
          if (Number(res.status) === 200) {
            const corpCode = res.data.merchantCode;
            this.corpCode = res.data.merchantCode;
            const parkName = parkInfo && parkInfo.parkName ? parkInfo.parkName : '';
            this.getTourGuideParkList(corpCode, parkName, cb);
          }
        });
    },
    getTourGuideParkList(code, parkName, cb) {
      let list = [];
      const url = '/manage/guide/api/guidePublicServiceApi/listPublicServicePage4Mp';
      this.getAxios(url, {
        corpCode: code,
        cityName: '',
        pageSize: 100,
        currPage: 1,
        parkName: parkName || '',
      }, host_pub).then(res => {
        list = res.data.rows;
        cb(list);
      });
    },
    getSingleAxios() {
      return this.getAxios('/merchant/api/merchantWholeMarket/getOpenSingle').then(res => {
        return res;
      });
    },
  },
};
