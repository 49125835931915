import Vue from 'vue';

const vm = new Vue();

/* ---------------------------------  用户  --------------------------------- */

/**
 * 获取宋城用户信息
 */
export const getUserInfo = () => vm.getAxios('/leaguer/api/userLeaguer/manage/leaguerInfo');


/* ---------------------------------  会员  --------------------------------- */
/**
 * 获取会员价
 * @param {String} codePrice code与价格拼接字符,多个逗号分隔  eg:spec4645444634:0.01,spec564654646445:0.02
 */
export const getDiscount = codePrice => vm.postAxios('/leaguer/api/leaguer/pubs/product/discountForMdse', {
  codePrice,
});

export const updateMemberInfo = params => vm.postAxios('/leaguer/api/userLeaguer/manage/leaguerUpdate', params);
