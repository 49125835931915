export const detailRouters = [{
  path: '/detail/ticket',
  name: 'detailTicket',
  component: resolve => require(['@/views/detail/detailTicket.vue'], resolve),
}, {
  path: '/detail/hotel',
  name: 'detailHotel',
  component: resolve => require(['@/views/detail/detailHotel.vue'], resolve),
}, {
  path: '/detail/repast',
  name: 'detailRepast',
  component: resolve => require(['@/views/detail/detailRepast.vue'], resolve),
}, {
  path: '/detail/route',
  name: 'detailRoute',
  component: resolve => require(['@/views/detail/detailRoute.vue'], resolve),
}, {
  path: '/detail/show',
  name: 'detailShow',
  component: resolve => require(['@/views/detail/detailShow.vue'], resolve),
}, {
  path: '/detail/strategy',
  name: 'detailStrategy',
  component: resolve => require(['@/views/detail/detailStrategy.vue'], resolve),
}, {
  path: '/detail/tourGuide',
  name: 'detailTourGuide',
  component: resolve => require(['@/views/tourGuide/detail/index.vue'], resolve),
}, {
  path: '/detail/tourGuide/search',
  name: 'detailTourGuideSearch',
  component: resolve => require(['@/views/tourGuide/search/index.vue'], resolve),
}, {
  path: '/detail/tourGuide/detailLine',
  name: 'detailTourGuideLine',
  component: resolve => require(['@/views/tourGuide/detailLine/index.vue'], resolve),
}, {
  path: '/detail/routeCalendar',
  name: 'routeCalendar',
  component: resolve => require(['@/components/routeDetail/index.vue'], resolve),
}, {
  path: '/detail/tickets',
  name: 'DetailTickets',
  meta: {
    pagePoint: true,
  },
  component: resolve => require(['@/views/detail/detailTickets'], resolve),
}, {
  path: '/detail/shop',
  name: 'DetailShop',
  meta: {
    pagePoint: true,
  },
  component: resolve => require(['@/views/detail/detailShop'], resolve),
}, {
  path: '/comment/main',
  name: 'commentMain',
  component: resolve => require(['@/views/comment/index.vue'], resolve),
}];
