export const loginRouters = [{
    path: '/login',
    name: 'login',
    component: resolve => require(['@/views/login/login.vue'], resolve)  
},{
    path: '/register',
    name: 'register',
    component: resolve => require(['@/views/login/register.vue'], resolve)  
},{
    path: '/bind/mobile',
    name: 'BindMobile',
    component: resolve => require(['@/views/login/bindMobile.vue'], resolve)
},{
    path: '/forgetPw',
    name: 'forgetPw',
    component: resolve => require(['@/views/login/forgetPw.vue'], resolve)
},{
    path: '/resetPassword',
    name: 'resetPassword',
    component: resolve => require(['@/views/login/resetPassword.vue'], resolve)
}]