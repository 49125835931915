/**
 * 全员营销路由
 */
const promotionRoute = [
  {
    path: '/workBench',
    name: 'WorkBench',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/workBench'),
    meta: {
      title: '全员营销',
      tag: 'promotion',
    },
  },
  {
    path: '/productExtendList',
    name: 'ProductExtendList',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/productExtendList'),
    meta: {
      tag: 'promotion',
    },
  },
  {
    path: '/promoteProductList',
    name: 'PromoteProductList',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/promoteProductList'),
    meta: {
      title: '产品列表',
      tag: 'promotion',
    },
  },
  {
    path: '/moneyRecord',
    name: 'MoneyRecord',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/moneyRecord'),
    meta: {
      tag: 'promotion',
    },
  },
  {
    path: '/moneyTake',
    name: 'MoneyTake',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/moneyTake'),
    meta: {
      tag: 'promotion',
    },
  },
  {
    path: '/moneyDetail',
    name: 'MoneyDetail',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/moneyDetail'),
    meta: {
      tag: 'promotion',
    },
  },
  {
    path: '/extendOrder',
    name: 'ExtendOrder',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/extendOrder'),
    meta: {
      tag: 'promotion',
    },
  },
  {
    path: '/inviteOrder',
    name: 'InviteOrder',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/inviteOrder'),
    meta: {
      tag: 'promotion',
    },
  },
  {
    path: '/promotionOrderDetail',
    name: 'promotionOrderDetail',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/orderDetail'),
    meta: {
      tag: 'promotion',
    },
  },
  {
    path: '/myInvite',
    name: 'MyInvite',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/myInvite'),
    meta: {
      tag: 'promotion',
    },
  },
  {
    path: '/gathering',
    name: 'gathering',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/gathering'),
    meta: {
      tag: 'promotion',
    },
  },
  {
    path: '/inviteFriend',
    name: 'InviteFriend',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/inviteFriend'),
    meta: {
      tag: 'promotion',
    },
  },
  {
    path: '/activityExtend',
    name: 'ActivityExtend',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/activityExtend'),
    meta: {
      tag: 'promotion',
    },
  },
  {
    path: '/invite',
    name: 'Invite',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/invite'),
    meta: {
      tag: 'promotion',
    },
  },
  {
    path: '/oldQyBindXj',
    name: 'oldQyBindXj',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/redirectTransfer'),
    meta: {
      tag: 'promotion',
    },
  },
  {
    path: '/noticeList',
    name: 'noticeList',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/announcementsList'),
    meta: {
      title: '公告',
      tag: 'promotion',
    },
  },
  {
    path: '/noticeDetails',
    name: 'noticeDetails',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/announcementDetails'),
    meta: {
      tag: 'promotion',
    },
  },
  {
    path: '/miniProgramQyyx',
    name: 'miniProgramQyyx',
    component: () => import(/* webpackChunkName: "route-promotion" */ '@/views/promotion/miniProgramQyyx'),
    meta: {
      tag: 'promotion',
    },
  },
];

export default promotionRoute;

