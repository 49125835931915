import qs from 'qs';
import store from '@/store';
import router from '../routers';
import { Dialog } from '@sdi/fish';
import querystring from 'query-string';
import { isWxAli, merchantInfoId } from '@/common/common';
import miniProgram from '@/common/miniProgram';
import axios from 'axios';
import VueCookies from 'vue-cookies';
// 在config.js文件中统一存放一些公共常量，方便之后维护
const { VUE_APP_BASEURL } = process.env;
const baseUrl = VUE_APP_BASEURL;
// const baseUrl = 'https://lotsmall.51dmq.com/';


const env = isWxAli();

function wxAliLogin(channl) {
  let rediurl;
  const nextPath = localStorage.getItem('sc_nextpath_20200216');
  if (nextPath) {
    rediurl = nextPath;
    localStorage.removeItem('sc_nextpath_20200216');
  } else {
    rediurl = window.location.href;
  }
  const params = {
    channl,
    redirectUrl: encodeURIComponent(rediurl),
    merchantInfoId,
  };
  const url = `${baseUrl }leaguer/api/authApi/auth?${ querystring.stringify(params)}`;
  window.location.href = url;
}

axios.defaults.withCredentials = true;
// 添加请求拦截器，在发送请求之前做些什么(**具体查看axios文档**)--------------------------------------------
axios.interceptors.request.use((config) => {
  let token;
  if (env.isWx || env.isAliPay) {
    // 微信，支付宝
    token = localStorage.getItem('sc_token_20200216');
  } else {
    //
    token = localStorage.getItem('sc_token_20200216');
  }
  if (config.url.includes('api/promoter/createUserAuth') && VueCookies.get('qyyxSpreadCode')) {
    config.headers['promote-code'] = `${VueCookies.get('qyyxSpreadCode')}`;
  }
  config.headers['access-token'] = token || '';
  store.dispatch('updateLoadingStatus', true);
  return config;
}, (error) => {
  // 请求错误时弹框提示，或做些其他事
  store.dispatch('updateLoadingStatus', false);
  return Promise.reject(error);
});

// 添加响应拦截器(**具体查看axios文档**)----------------------------------------------------------------
axios.interceptors.response.use(async (response) => {
  store.dispatch('updateLoadingStatus', false);
  if (response.data.status === 500) {
    Dialog.alert({
      title: '提示',
      message: `${response.data.status },${ response.data.message}`,
    });
  } else if (response.data.status === 400 || response.data.code === 1004001005) {
    const respon = response;
    respon.response = {
      status: 400,
    };
    errorState(respon);
  } else if (response.data.code === 403) {
    // 全员token失效拦截
    errorState({ ...response, response: { status: 403 } });
  } else if (response.data.code === 1005003113) {
    // 1005003113 用户中心弱口令整改
    Dialog.alert({
      title: '提示',
      message: `${ response.data.message}`,
    }).then(() => {
      const redirSign = window.location.href.split('redir=');
      const redir = redirSign.length > 1 ? redirSign[1] : '';
      router.push({
        path: '/forgetPw',
        query: {
          redir,
          type: 'weakPasswordUpdateNeed',
          ...router.currentRoute.query,
        },
      });
    });
  } else {
    return response.data;
  }
}, (error) => {
  // 对响应错误做点什么
  store.dispatch('updateLoadingStatus', false);
  if (error && error.response && error.response.status) {
    switch (error.response.status) {
      case 403:
        error._message = '拒绝访问';
        break;
      case 404:
        error._message = `请求地址出错:${ error.response.config.url}`;
        break;
      case 408:
        error._message = '请求超时';
        break;
      case 500:
        error._message = '服务器繁忙';
        break;
      case 501:
        error._message = '服务未实现';
        break;
      case 502:
        error._message = '网关错误';
        break;
      case 503:
        error._message = '服务不可用';
        break;
      case 504:
        error._message = '网关超时';
        break;
      case 505:
        error._message = 'HTTP版本不受支持';
        break;
      case 429:
        error._message = '活动火热进行中，请稍后再试';
        break;
      default:
        if (typeof error.response.data === 'object' && (error.response.data.message || error.response.data.respMsg)) {
          error._message = error.response.data.message || error.response.data.respMsg;
        }
        break;
    }
  }
  return Promise.reject(error);
});

// 封装数据返回失败提示函数---------------------------------------------------------------------------
function errorState(response) {
  if (response && response.response) {
    if ([400, 403, 401].includes(response.response.status)) {
      // token过期、token不存在
      let channl = '';
      if (miniProgram.isMP()) {
        if (miniProgram.isMP() === 'wx') {
          try {
            const q = querystring.parseUrl(window.location.href);
            if (q.query.token) {
              delete q.query.token;
            }
            const redirect = window.encodeURIComponent(querystring.stringifyUrl(q));
            miniProgram.navigateTo(
              `/pages/authorization/index?redirect=${ redirect }`,
            );
          } catch (e) {
            miniProgram.navigateTo(
              '/pages/authorization/index',
            );
          }
          return;
        } else {
          miniProgram.redirectTo('/pages/index/index');
          return;
        }
      }
      if (env.isWx) {
        // 微信内置浏览器
        channl = 'WEIXIN';
        wxAliLogin(channl);
      } else if (env.isAliPay) {
        // 支付宝内置浏览器
        channl = 'ALI';
        wxAliLogin(channl);
      } else {
        const nextPath = localStorage.getItem('sc_nextpath_20200216') || window.location.href;

        const redir = nextPath ? encodeURIComponent(nextPath) : encodeURIComponent('/');
        if (nextPath) {
          localStorage.removeItem('sc_nextpath_20200216');
        }
        if (!nextPath.includes('redir')) {
          router.replace({ path: `/login?redir=${redir}` });
        }
      }
    } else if (Number(response.response.status) === 410) {
      // 手机浏览器登录用户直接用手机号码登录不需要绑定手机号码，也就支付宝和微信H5用户需要
      // 410状态微信h5及支付宝h5用户登录后未绑定手机号码
      // 需要跳转手机号码绑定页面
      const rediurl = encodeURIComponent(window.location.href);
      if (!rediurl.includes('rediurl')) {
        router.push({
          path: `/bind/mobile?rediurl=${ rediurl}`,
        });
      }
    } else {
      Dialog.alert({
        title: '提示',
        message: response._message ? response._message : '连接错误',
      });
    }
  } else {
    const originalRequest = response.config;
    if (response.code === 'ECONNABORTED' && response.message.indexOf('timeout') !== -1 && !originalRequest._retry) {
      response._message = '请求超时';
      Dialog.alert({
        title: '提示',
        message: response._message,
      });
    }
  }
}
// 封装axios--------------------------------------------------------------------------------------
export function apiAxios(method, url, params, base, igError, jsonFlag) {
  params.sc20200221 = (new Date()).valueOf();
  if (jsonFlag) {
    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
  } else {
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
  }
  params = Object.assign(params, {
    merchantInfoId,
  });
  const httpDefault = {
    method,
    url,
    // `params` 是即将与请求一起发送的 URL 参数
    // `data` 是作为请求主体被发送的数据
    params: method === 'GET' || method === 'DELETE' ? params : null,
    // eslint-disable-next-line no-nested-ternary
    data: method === 'POST' || method === 'PUT' ?
      jsonFlag ? params : qs.stringify(params) : null,
    timeout: 20000,
    baseURL: base || baseUrl || process.env.VUE_APP_BASEURL,
  };
  // 注意**Promise**使用(Promise首字母大写)

  return new Promise((resolve, reject) => {
    axios(httpDefault)
      // 此处的.then属于axios
      .then((response) => {
        if (response && (Number(response.status) === 200 ||
        (response.status && response.status.success) || response.success || (response.flag && response.flag === 'success'))) {
          // 请求成功
          resolve(response);
        } else {
          resolve(response);
          if (!igError) {
            Dialog.alert({
              title: '提示',
              message: response.message || response.msg,
            });
          }
          // 阻止代码继续执行
        }
      }).catch((response) => {
        errorState(response);
        // 过滤410和400错误，410绑定手机号码和400未登录在errorState中已经针对这两个做了处理，不需要页面处理
        if (response && response.response && response.response.status
          && (Number(response.response.status) !== 400
          && Number(response.response.status) !== 410)) {
          reject(response);
        }
      })
      .catch((error) => {
        errorState(error);
        reject(error);
      });
  });
}

const jsonAxios = (url, params, base, igError) => apiAxios('POST', url, params || {}, base, igError, true);
const postAxios = (url, params, base, igError) => apiAxios('POST', url, params || {}, base, igError);
const getAxios = (url, params, base, igError) => apiAxios('GET', url, params || {}, base, igError);
const putAxios = (url, params, base, igError) => apiAxios('PUT', url, params || {}, base, igError);
const deleteAxios = (url, params, base, igError) => apiAxios('DELETE', url, params || {}, base, igError);

/* eslint-disable */
const jsonAxiosDel = (url, params, base, igError) => {
  const paramsTemp = params || {};
  const merchantInfoId = localStorage.getItem('xjsc_vue_2018_12_19_mid');
  paramsTemp.merchantInfoId = Number(merchantInfoId);
  for (const key in paramsTemp) {
    if (Object.prototype.hasOwnProperty.call(paramsTemp, key)) {
      const element = paramsTemp[key];
      if (element === '' || element === null || element === undefined) {
        delete paramsTemp[key];
      }
    }
  }
  return apiAxios('POST', url, paramsTemp, base, igError, true);
};
/* eslint-enable */
export {
  jsonAxios,
  jsonAxiosDel,
  postAxios,
  getAxios,
  putAxios,
  deleteAxios,
};

// 输出函数getAxios、postAxios、putAxios、deleteAxios，供其他文件调用-----------------------------
// Vue.js的插件应当有一个公开方法 install。这个方法的第一个参数是 Vue 构造器，第二个参数是一个可选的选项对象。
export default {
  install(Vue) {
    Vue.prototype.getAxios = (url, params, base, igError) => apiAxios('GET', url, params || {}, base, igError);
    Vue.prototype.jsonAxios = (url, params, base, igError) => apiAxios('POST', url, params || {}, base, igError, true);
    Vue.prototype.postAxios = (url, params, base, igError) => apiAxios('POST', url, params || {}, base, igError);
    Vue.prototype.putAxios = (url, params, base, igError) => apiAxios('PUT', url, params || {}, base, igError);
    Vue.prototype.deleteAxios = (url, params, base, igError) => apiAxios('DELETE', url, params || {}, base, igError);
  },
};
