const { VUE_APP_MODE } = process.env;

// 测试环境
let merchantInfoId = '249';
let appid = 'wx4b0f4569a3d98851';
let host_pub = 'https://scpubs.sendinfo.com.cn';
let pubUrl = 'https://scpubswap.sendinfo.com.cn';
let scH5Url = '//sch5.sendinfo.com.cn';
let seatsUrl = 'http://scwap.sendinfo.com.cn';
if (VUE_APP_MODE === 'prod') {
  // 生产环境
  merchantInfoId = '7';
  appid = 'wx4b0f4569a3d98851';
  host_pub = 'https://scpubs.51dmq.com';
  pubUrl = 'https://scpubswap.51dmq.com';
  scH5Url = '//sc.51dmq.com';
  seatsUrl = 'https://51dmq.com';
}

const linkBaseUrl = `${window.location.protocol}//${window.location.host}`;


function isWxAli() {
  return {
    isWx: /MicroMessenger/.test(window.navigator.userAgent),
    isAliPay: /AlipayClient/.test(window.navigator.userAgent),
  };
}

function wxPay(payParams) {
  function onBridgeReady() {
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest', payParams.jsons,
      (res) => {
        // alert(JSON.stringify(res))
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          payParams.success();
        } else {
          payParams.fail();
        }
      },
    );
  }

  if (typeof WeixinJSBridge === 'undefined') {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
      document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
    }
  } else {
    onBridgeReady();
  }
}

function changeDateType(year, month, day) {
  const y = year;
  let m = month;
  if (m < 10) m = `0${ m}`;
  let d = day;
  if (d < 10) d = `0${ d}`;
  return `${y }-${ m }-${ d}`;
}
function compareDateTime(date1, date2) {
  const oDate1 = new Date(date1);
  const oDate2 = new Date(date2);
  if (oDate1.getTime() > oDate2.getTime()) {
    return true;
  } else {
    return false;
  }
}
function curentDateTime() {
  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  if (month < 10) {
    month = `0${ month}`;
  }
  if (day < 10) {
    day = `0${ day}`;
  }
  return `${year }/${ month }/${ day } ${ hour }:${ minute }:${ second}`;
}
function add0(m) {
  return m < 10 ? `0${m}` : m;
}
// 数据乱序
function randomSort(arr) {
  for (let i = arr[0].length - 1; i >= 0; i--) {
    const rIndex = Math.floor(Math.random() * (i + 1));
    for (let arrIndex = 0; arrIndex < arr.length; arrIndex++) {
      const item = arr[arrIndex];
      const temp = item[rIndex];
      item[rIndex] = item[i];
      item[i] = temp;
    }
  }
  return arr;
}

// 脱敏显示统一格式
const idFilter = function (value) {
  if (value) {
    if (/AlipayClient/.test(window.navigator.userAgent)) {
      // 身份证只显示第一位和最后一位，其他用*号
      return `${value.slice(0, 1) }****************${ value.slice(value.length - 1)}`;
    } else {
      return `${value.slice(0, 6) }**********${ value.slice(value.length - 2)}`;
    }
  } else {
    return '';
  }
};
const nameFilter = function (value) {
  let star = '';
  for (let i = 0; i < value.length - 1; i++) {
    star += '*';
  }
  if (/AlipayClient/.test(window.navigator.userAgent)) {
    // 姓名只显示最后一个字，前面用*号
    return `${star}${ value.slice(value.length - 1)}`;
  } else {
    return `${value.slice(0, value.length - 2) }*${ value.slice(value.length - 1)}`;
  }
};
const phoneFilter = function (value) {
  if (/AlipayClient/.test(window.navigator.userAgent)) {
    // 手机号显示前面3位和最后2位，其他用*号
    return `${value.slice(0, 3) }******${ value.slice(value.length - 2)}`;
  } else {
    return `${value.slice(0, 3) }****${ value.slice(value.length - 4)}`;
  }
};
const checkIdCard = (val) => {
  const ID = val;
  if (!ID) return false;
  if (typeof ID !== 'string' && typeof ID !== 'number') {
    return false;
  }
  const city = { 11: '北京', 12: '天津', 13: '河北', 14: '山西', 15: '内蒙古', 21: '辽宁', 22: '吉林', 23: '黑龙江 ', 31: '上海', 32: '江苏', 33: '浙江', 34: '安徽', 35: '福建', 36: '江西', 37: '山东', 41: '河南', 42: '湖北 ', 43: '湖南', 44: '广东', 45: '广西', 46: '海南', 50: '重庆', 51: '四川', 52: '贵州', 53: '云南', 54: '西藏 ', 61: '陕西', 62: '甘肃', 63: '青海', 64: '宁夏', 65: '新疆', 71: '台湾', 81: '香港', 82: '澳门', 83: '台湾居民', 91: '国外' };
  const birthday =
    `${ID.substr(6, 4)
    }/${
    Number(ID.substr(10, 2))
    }/${
    Number(ID.substr(12, 2))}`;
  const d = new Date(birthday);
  const newBirthday =
    `${d.getFullYear()
    }/${
    Number(d.getMonth() + 1)
    }/${
    Number(d.getDate())}`;
  const currentTime = new Date().getTime();
  const time = d.getTime();
  const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  const arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
  let sum = 0;
  let i;
  let residue = '';
  if (!/^\d{17}(\d|x)$/i.test(ID)) { return false; }
  if (city[ID.substr(0, 2)] === undefined) { return false; }
  if (time >= currentTime || birthday !== newBirthday) { return false; }
  for (i = 0; i < 17; i++) {
    sum += ID.substr(i, 1) * arrInt[i];
  }
  residue = arrCh[sum % 11];
  if (residue !== ID.substr(17, 1).toUpperCase()) { return false; }
  return true;
};
export {
  isWxAli,
  wxPay,
  compareDateTime,
  curentDateTime,
  add0,
  merchantInfoId,
  appid,
  changeDateType,
  host_pub,
  linkBaseUrl,
  randomSort,
  idFilter,
  nameFilter,
  phoneFilter,
  checkIdCard,
  pubUrl,
  scH5Url,
  seatsUrl,
};
