import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const context = require.context('./modules', true, /\.js$/);
const modules = context.keys().reduce((total, path) => {
  const name = path.replace(/\.\/|\.js/g, '');
  total[name] = context(path).default;
  return total;
}, {});


const store = new Vuex.Store({
  state: {
    isLoading: false,
    count: 0,
    token: '11',
    pageNoScroll: false, // 页面不能滚动
  },
  mutations: {
    setPageNoScrollMutations(state, flag) {
      state.pageNoScroll = flag;
    },
    setTokenMutations(state, token) {
      state.token = token;
    },
    add(state, n) {
      state.count += n;
    },
    loaddingChange(state, flag) {
      state.isLoading = flag;
    },
  },
  getters: {
    count(state) {
      state.count += 100;
      return state.count;
    },
  },
  actions: {
    setPageNoScroll({
      commit,
    }, flag) {
      commit('setPageNoScrollMutations', flag);
    },
    loaddingChangeAction({
      commit,
    }, flag) {
      commit('loaddingChange', flag);
    },
    addAction(context) {
      context.commit('add', 10);
    },
    setTokenAction({
      commit,
    }, token) {
      commit('setTokenMutations', token);
    },
  },
  modules,
});

export default store;
