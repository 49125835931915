import Vue from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './routers';
import store from './store';
// import FastClick from 'fastclick'
import es6promise from 'es6-promise';
import apiPlugin from './axios/index';
import VueAMap from 'vue-amap';
import VideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';
import 'vue-video-player/src/custom-theme.css';

import 'lib-flexible';
import '@/assets/fonts/iconfont.css';
import '@/assets/font/iconfont.css';
import '@/assets/weatherFont/iconfont.css';
import '@/assets/weatherFont/iconfont.js';
import '@sdi/fish/lib/style/base.less';

import {
  AjaxPlugin,
  AlertPlugin,
  ToastPlugin,
  LoadingPlugin,
  Swiper,
  SwiperItem,
  Scroller,
  ConfirmPlugin,
} from 'vux';
// import VConsole from 'vconsole';

import miniProgram from '@/common/miniProgram';
import globalMixins from '@/mixins/global';

es6promise.polyfill();
Vue.config.productionTip = false;
// FastClick.attach(document.body)
// const vConsole = new VConsole();
// Vue.use(vConsole);

Vue.use(VideoPlayer);
miniProgram.initMiniProgram();
// 引入高德地图
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'b547d05d07535668173a50b6d644ffce', //
  plugin: ['Autocomplete', 'PlaceSearch', 'Scale', 'ToolBar', 'PolyEditor', 'AMap.CircleEditor', 'Geolocation'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4',
});

// 全局初始化axios请求
Vue.use(AlertPlugin);
Vue.use(ToastPlugin, {
  position: 'middle',
});
Vue.use(VueCookies);
Vue.use(apiPlugin);
Vue.component('Scroller', Scroller);
Vue.component('Swiper', Swiper);
Vue.component('SwiperItem', SwiperItem);
Vue.use(AjaxPlugin);
Vue.use(LoadingPlugin);
Vue.use(ConfirmPlugin);

Vue.prototype.$carInfo = { // 购物车数据对象
  isRoute: false,
  isRepast: false,
  toast: '',
  products: {
    show: [],
    ticket: [],
    hotel: [],
    route: [],
    repast: [],
  },
};
// 混合，全局使用api
Vue.mixin(globalMixins);

const vm = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
export default vm;
