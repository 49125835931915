// 抽奖相关路由
const routes = [
  // 抽奖页面
  {
    path: '/luckDraw/index',
    name: 'luckDrawIndex',
    meta: {
      title: '幸运大抽奖',
    },
    component: () => import(/* webpackChunkName: "luckDrawIndex" */ '@/views/luckDraw/index'),
  },
  {
    path: '/luckDraw/record',
    name: 'luckDrawRecord',
    meta: {
      title: '我的中奖记录',
    },
    component: () => import(/* webpackChunkName: "luckDrawRecord" */ '@/views/luckDraw/record'),
  },
];

export default routes;
