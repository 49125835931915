export const listRouters = [{
  path: '/ticket/list',
  name: 'listTicket',
  component: resolve => require(['@/views/list/listTicket.vue'], resolve)  
},{
  path: '/hotel/list',
  name: 'listHotel',
  component: resolve => require(['@/views/list/listHotel.vue'], resolve)  
},{
  path: '/repast/list',
  name: 'listRepast',
  component: resolve => require(['@/views/list/listRepast.vue'], resolve)  
},{
  path: '/route/list',
  name: 'listRoute',
  component: resolve => require(['@/views/list/listRoute.vue'], resolve)  
},{
  path: '/show/list',
  name: 'listShow',
  component: resolve => require(['@/views/list/listShow.vue'], resolve)  
},{
  path: '/strategy/list',
  name: 'listStrategy',
  component: resolve => require(['@/views/list/listStrategy.vue'], resolve)  
},{
  path: '/tourGuide/list',
  name: 'listTourGuide',
  component: resolve => require(['@/views/tourGuide/list/index.vue'], resolve)  
},{
  path: '/tickets/list',
  name: 'ListTickets',
  component: resolve => require(['@/views/list/listTickets'], resolve)
},{
  path: '/goods/list',
  name: 'ListGoods',
  component: resolve => require(['@/views/list/listGoods/index.vue'], resolve)
},{
  path: '/result/main',
  name: 'result',
  component: resolve => require(['@/views/result/index.vue'], resolve)  
}]