export const orderRouters = [{
  path: '/order/ticket',
  name: 'orderTicket',
  component: resolve => require(['@/views/order/orderTicket.vue'], resolve),
}, {
  path: '/order/hotel',
  name: 'orderHotel',
  component: resolve => require(['@/views/order/orderHotel.vue'], resolve),
}, {
  path: '/order/repast',
  name: 'orderRepast',
  component: resolve => require(['@/views/order/orderRepast.vue'], resolve),
}, {
  path: '/order/route',
  name: 'orderRoute',
  component: resolve => require(['@/views/order/orderRoute.vue'], resolve),
}, {
  path: '/order/show',
  name: 'orderShow',
  component: resolve => require(['@/views/order/orderShow.vue'], resolve),
}, {
  path: '/order/tickets',
  name: 'orderTickets',
  meta: {
    pagePoint: true,
  },
  component: resolve => require(['@/views/order/orderTickets.vue'], resolve),
}, {
  path: '/order/shop',
  name: 'orderShop',
  component: resolve => require(['@/views/order/orderShop.vue'], resolve),
}, {
  path: '/order/shop/address',
  name: 'orderShop',
  component: resolve => require(['@/views/order/orderShopAddress.vue'], resolve),
}, {
  path: '/contactEdit',
  name: 'contactEdit',
  component: resolve => require(['@/views/contactEdit/index.vue'], resolve),
}, {
  path: '/order/reservation/:orderNo',
  name: 'orderReservation',
  component: resolve => require(['@/views/order/reservevation/index.vue'], resolve),
}, {
  path: '/order/reserve/booking',
  name: 'orderReservationBooking',
  component: resolve => require(['@/views/order/reservevation/booking.vue'], resolve),
}, {
  path: '/order/reserve/booked',
  name: 'orderReservationBooked',
  component: resolve => require(['@/views/order/reservevation/booked.vue'], resolve),
}];
